.Navigation {
    background: var(--whiteBaseColor);

    @media (--desktop) {
        width: auto;
    }
}

.Inner {
    position: relative;
    padding: var(--studyPageNavigationVerticalPadding) var(--wrapperHorizontalPadding);

    @media (--tablet) {
        position: relative;
        padding: 8px var(--studyPageNavigationTabletHorizontalPadding);
    }
}

.Variant--rounded {
    @media (--tablet) {
        @mixin themeRoundedCornersMedium;
    }
}

&.Variant--shadowed {
    @media (--tablet) {
        @mixin themeBoxShadowLarge;
    }
}
