.StudyPageNavigationControls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 8px;

    @media (--tablet) {
        align-items: center;
    }
}

.ExpandContainer {
    flex: 1;

    @media (--mobileOnly) {
        display: flex;
    }

    @media (--aboveWrapper) {
        position: relative;
    }
}

.ExpandButton {
    @mixin animatePropertyFaster transform;

    @mixin onFocus {
        @mixin focusVisible -4px;
    }
    transform-origin: left;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (--tablet) {
        padding: 12px 0;
    }

    &:hover {
        transform: scale(1.085);
    }

    &.ExpandButton {
        display: flex;
    }
}

.ExpandButtonIcon {
    @mixin animatePropertyFaster transform;
    @mixin fixedRatioSvgIcon 24px;
    transform: rotate(-90deg);

    @media (--tablet) {
        transform: rotate(90deg);
    }

    &.ExpandButtonIcon--flipped {
        transform: rotate(90deg);

        @media (--tablet) {
            transform: rotate(-90deg);
        }
    }
}

.ExpandButtonLabel {
    @mixin h50;
    @mixin hyphenateWords;
    font-weight: 600;
    margin-left: 8px;

    @media (--tablet) {
        /* TODO: Fix me */
        overflow: hidden;
        height: 32px;
    }
}

.Content {
    @media (--mobileOnly) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        max-height: calc(100vh - 96px - (2 * var(--studyPageNavigationVerticalPadding)));
        overflow: visible auto;
    }
}

.ApplyButton {
    min-height: 64px;

    @media (--tablet) {
        min-height: auto;
    }
}

.ApplyIcon {
    &:global(.SvgIcon.SvgIcon) {
        display: none;

        @media (--tablet) {
            display: block;
        }
    }
}
