.NavigationLink.NavigationLink {
    @mixin animatePropertyFaster text-shadow;

    @mixin onFocus {
        @mixin focusVisible 2px;
    }
    position: relative;
    color: var(--blackLighten20Color);
    margin: 12px 0 12px 24px;

    &:hover {
        @mixin visuallyBold;
        color: var(--blackBaseColor);

        & .LinkLabel {
            border-bottom-color: currentColor;
        }
    }
}

.LinkLabelWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.LinkLabel {
    @mixin animatePropertyFaster single-arg(color, border-color);
    @mixin hyphenateWords;
    @mixin p20;
    border-bottom: 2px solid transparent;
}

.Dot {
    display: block;
    background: var(--blackBaseColor);
    min-width: 0.75em;
    width: 0.75em;
    height: 0.75em;
    border-radius: 100%;
    margin-left: -24px;
}

.Variant--selected {
    color: var(--blackBaseColor);

    & .LinkLabel {
        @mixin visuallyBold;

        @media (--tablet) {
            @mixin h50;
        }
    }
}
